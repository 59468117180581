

import * as Icon from "../../Icon.mjs";
import * as Link from "../common/Link/Link.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProgressBar from "../common/ProgressBar/ProgressBar.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

function stepNumber$p(idx, ctx) {
  return Utils.Translations.txr("js.success_path.step_no")({
              no: idx
            }, ctx);
}

var header$p = Utils.Translations.tr("js.success_path.header");

function jobsWithResolution$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_custom_resolution")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithClient$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_client")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithCustomTemplate$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_custom_template")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithSentSms$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_sent_sms")({
              percent: percent,
              days: lookback
            }, ctx);
}

function smsWithTracking$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.sms_with_tracking")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithSkills$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_skills")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithTeam$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_team")({
              percent: percent,
              days: lookback
            }, ctx);
}

function workersWithTerritories$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.workers_with_territories")({
              percent: percent,
              days: lookback
            }, ctx);
}

function usersWithGeoLocation$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.users_with_geo_location")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithWorkTime$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_work_time")({
              percent: percent,
              days: lookback
            }, ctx);
}

function jobsWithEnRouteTime$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.jobs_with_en_route_time")({
              percent: percent,
              days: lookback
            }, ctx);
}

function scheduledJobs$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.scheduled_jobs")({
              percent: percent,
              days: lookback
            }, ctx);
}

function fieldUsersWithChanges$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.field_users_with_changes")({
              percent: percent,
              days: lookback
            }, ctx);
}

function finishedJobs$p(percent, lookback, ctx) {
  return Utils.Translations.txr("js.success_path.metrics.finished_jobs")({
              percent: percent,
              days: lookback
            }, ctx);
}

var seeInstructions$p = Utils.Translations.tr("js.success_path.see_instructions");

var styles = HeaderModuleCss;

function stepName$p(step, ctx) {
  return Utils.Translations.tr("js.success_path.steps." + Context_Types.SuccessPath.Step.stringCode(step))(ctx);
}

var addFieldWorkersInProgress$p = Utils.Translations.txr("js.success_path.steps.add_field_workers.in_progress");

var addFieldWorkersComplete$p = Utils.Translations.tr("js.success_path.steps.add_field_workers.complete");

var uploadClientsInProgress$p = Utils.Translations.txr("js.success_path.steps.upload_clients.in_progress");

var uploadClientsComplete$p = Utils.Translations.tr("js.success_path.steps.upload_clients.complete");

var setTerritoriesForWorkersInProgress$p = Utils.Translations.txr("js.success_path.steps.set_territories_for_workers.in_progress");

var setTerritoriesForWorkersComplete$p = Utils.Translations.tr("js.success_path.steps.set_territories_for_workers.complete");

var addSkillInProgress$p = Utils.Translations.txr("js.success_path.steps.add_skill.in_progress");

var addSkillComplete$p = Utils.Translations.tr("js.success_path.steps.add_skill.complete");

var addTerritoryInProgress$p = Utils.Translations.txr("js.success_path.steps.add_territory.in_progress");

var addTerritoryComplete$p = Utils.Translations.tr("js.success_path.steps.add_territory.complete");

var setSkillsInTemplatesInProgress$p = Utils.Translations.txr("js.success_path.steps.set_skills_in_templates.in_progress");

var setSkillsInTemplatesComplete$p = Utils.Translations.tr("js.success_path.steps.set_skills_in_templates.complete");

function HeaderSuccessPath$StepName(Props) {
  var ctx = Props.ctx;
  var step = Props.step;
  var text;
  switch (step.code) {
    case "AddFieldWorkers" :
        var match = step.status;
        if (match.TAG === "Complete") {
          text = addFieldWorkersComplete$p(ctx);
        } else {
          var match$1 = match._0;
          switch (match$1.TAG) {
            case "CountProgress" :
                var target = match$1._0.target;
                text = target > 1 ? addFieldWorkersInProgress$p({
                        count: target
                      }, ctx) : addFieldWorkersComplete$p(ctx);
                break;
            case "PercentProgress" :
            case "BoolProgress" :
                text = addFieldWorkersComplete$p(ctx);
                break;
            
          }
        }
        break;
    case "AddSkill" :
        var match$2 = step.status;
        if (match$2.TAG === "Complete") {
          text = addSkillComplete$p(ctx);
        } else {
          var match$3 = match$2._0;
          switch (match$3.TAG) {
            case "CountProgress" :
                var target$1 = match$3._0.target;
                text = target$1 > 1 ? addSkillInProgress$p({
                        count: target$1
                      }, ctx) : addSkillComplete$p(ctx);
                break;
            case "PercentProgress" :
            case "BoolProgress" :
                text = addSkillComplete$p(ctx);
                break;
            
          }
        }
        break;
    case "AddTerritory" :
        var match$4 = step.status;
        if (match$4.TAG === "Complete") {
          text = addTerritoryComplete$p(ctx);
        } else {
          var match$5 = match$4._0;
          switch (match$5.TAG) {
            case "CountProgress" :
                var target$2 = match$5._0.target;
                text = target$2 > 1 ? addTerritoryInProgress$p({
                        count: target$2
                      }, ctx) : addTerritoryComplete$p(ctx);
                break;
            case "PercentProgress" :
            case "BoolProgress" :
                text = addTerritoryComplete$p(ctx);
                break;
            
          }
        }
        break;
    case "SetSkillsInTemplates" :
        var match$6 = step.status;
        if (match$6.TAG === "Complete") {
          text = setSkillsInTemplatesComplete$p(ctx);
        } else {
          var match$7 = match$6._0;
          switch (match$7.TAG) {
            case "CountProgress" :
                var target$3 = match$7._0.target;
                text = target$3 > 1 ? setSkillsInTemplatesInProgress$p({
                        count: target$3
                      }, ctx) : setSkillsInTemplatesComplete$p(ctx);
                break;
            case "PercentProgress" :
            case "BoolProgress" :
                text = setSkillsInTemplatesComplete$p(ctx);
                break;
            
          }
        }
        break;
    case "SetTerritoriesForWorkers" :
        var match$8 = step.status;
        if (match$8.TAG === "Complete") {
          text = setTerritoriesForWorkersComplete$p(ctx);
        } else {
          var match$9 = match$8._0;
          switch (match$9.TAG) {
            case "CountProgress" :
                var target$4 = match$9._0.target;
                text = target$4 > 1 ? setTerritoriesForWorkersInProgress$p({
                        count: target$4
                      }, ctx) : setTerritoriesForWorkersComplete$p(ctx);
                break;
            case "PercentProgress" :
            case "BoolProgress" :
                text = setTerritoriesForWorkersComplete$p(ctx);
                break;
            
          }
        }
        break;
    case "UploadClients" :
        var match$10 = step.status;
        if (match$10.TAG === "Complete") {
          text = uploadClientsComplete$p(ctx);
        } else {
          var match$11 = match$10._0;
          switch (match$11.TAG) {
            case "CountProgress" :
                var target$5 = match$11._0.target;
                text = target$5 > 1 ? uploadClientsInProgress$p({
                        count: target$5
                      }, ctx) : uploadClientsComplete$p(ctx);
                break;
            case "PercentProgress" :
            case "BoolProgress" :
                text = uploadClientsComplete$p(ctx);
                break;
            
          }
        }
        break;
    default:
      text = stepName$p(step, ctx);
  }
  return React.createElement("span", undefined, text);
}

function HeaderSuccessPath$InstructionLink(Props) {
  var ctx = Props.ctx;
  var url = Props.url;
  var text = Props.text;
  var text$1 = text !== undefined ? Caml_option.valFromOption(text) : seeInstructions$p(ctx);
  return React.createElement("div", {
              className: styles.instructionLink
            }, React.createElement("a", {
                  className: styles.instructionLink,
                  href: url,
                  target: "blank"
                }, text$1, " ", React.createElement("i", {
                      className: Icon.style(undefined, undefined, undefined, "external-link-alt")
                    })));
}

function HeaderSuccessPath$ActionLink(Props) {
  var wire = Props.wire;
  var route = Props.route;
  var children = Props.children;
  return React.createElement("div", {
              className: styles.actionLink
            }, React.createElement(Link.make, {
                  wire: wire,
                  route: route,
                  target: "blank",
                  children: null
                }, children, " ", React.createElement("i", {
                      className: Icon.style(undefined, undefined, undefined, "external-link-alt")
                    })));
}

function HeaderSuccessPath$Description(Props) {
  var text = Props.text;
  return React.createElement("div", {
              className: styles.stepDescription
            }, text.split("\n\n").map(function (line, idx) {
                  return React.createElement("p", {
                              key: idx.toString()
                            }, line);
                }));
}

var addFieldWorkersInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_field_workers");

var addFieldWorkersProgress$p = Utils.Translations.txr("js.success_path.progress.add_field_workers");

var inviteUser$p = Utils.Translations.tr("js.success_path.invite_user");

var addFieldWorkersDescription$p = Utils.Translations.t("js.success_path.step_description.add_field_workers");

function HeaderSuccessPath$AddFieldWorkers(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "CountProgress" :
          var match = progress._0;
          var current = match.current;
          var target = match.target;
          tmp = current > 0 && target > 1 ? React.createElement("div", undefined, addFieldWorkersProgress$p({
                      count: target - current | 0
                    }, ctx)) : null;
          break;
      case "PercentProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addFieldWorkersDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addFieldWorkersInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "User",
                    VAL: {
                      NAME: "Field",
                      VAL: "New"
                    }
                  },
                  children: inviteUser$p(ctx)
                }));
}

var addOrEditTemplateInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_or_edit_template");

var addOrEditTemplate$p = Utils.Translations.tr("js.success_path.add_or_edit_template");

var addOrEditTemplateDescription$p = Utils.Translations.t("js.success_path.step_description.add_or_edit_template");

function HeaderSuccessPath$AddOrEditTemplate(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addOrEditTemplateDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addOrEditTemplateInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Template",
                    VAL: "Index"
                  },
                  children: addOrEditTemplate$p(ctx)
                }));
}

var addCustomResolutionInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_custom_resolution");

var addResolution$p = Utils.Translations.tr("js.success_path.add_resolution");

var addCustomResolutionDescription$p = Utils.Translations.t("js.success_path.step_description.add_custom_resolution");

function HeaderSuccessPath$AddCustomResolution(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addCustomResolutionDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addCustomResolutionInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: "ResolutionIndex",
                  children: addResolution$p(ctx)
                }));
}

var addTeamInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_team");

var configureTeams$p = Utils.Translations.tr("js.success_path.configure_teams");

var addTeamDescription$p = Utils.Translations.t("js.success_path.step_description.add_team");

function HeaderSuccessPath$AddTeam(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addTeamDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addTeamInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: "Shifts",
                  children: configureTeams$p(ctx)
                }));
}

var chooseTeamAsAssigneeInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.choose_team_as_assignee");

var chooseTeamAsAssigneeDescription$p = Utils.Translations.t("js.success_path.step_description.choose_team_as_assignee");

function HeaderSuccessPath$ChooseTeamAsAssignee(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: chooseTeamAsAssigneeDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: chooseTeamAsAssigneeInstructionUrl$p(ctx)
                }));
}

var setUpShiftsForWorkersInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.set_up_shifts_for_workers");

var configureShifts$p = Utils.Translations.tr("js.success_path.configure_shifts");

var configureShiftsProgress$p = Utils.Translations.txr("js.success_path.progress.set_up_shifts_for_workers");

var setUpShiftsForWorkersDescription$p = Utils.Translations.t("js.success_path.step_description.set_up_shifts_for_workers");

function HeaderSuccessPath$SetUpShiftsForWorkers(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "PercentProgress" :
          tmp = React.createElement("div", undefined, configureShiftsProgress$p({
                    target: progress._0.target
                  }, ctx));
          break;
      case "CountProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: setUpShiftsForWorkersDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: setUpShiftsForWorkersInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: "Shifts",
                  children: configureShifts$p(ctx)
                }));
}

var setUpShiftsForTeamsInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.set_up_shifts_for_teams");

var configureShifts$p$1 = Utils.Translations.tr("js.success_path.configure_shifts");

var configureShiftsProgress$p$1 = Utils.Translations.txr("js.success_path.progress.set_up_shifts_for_teams");

var setUpShiftsForWorkersDescription$p$1 = Utils.Translations.t("js.success_path.step_description.set_up_shifts_for_workers");

function HeaderSuccessPath$SetUpShiftsForTeams(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "PercentProgress" :
          tmp = React.createElement("div", undefined, configureShiftsProgress$p$1({
                    target: progress._0.target
                  }, ctx));
          break;
      case "CountProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: setUpShiftsForWorkersDescription$p$1(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: setUpShiftsForTeamsInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: "Shifts",
                  children: configureShifts$p$1(ctx)
                }));
}

var addClientInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_client");

var addClient$p = Utils.Translations.tr("js.success_path.add_client");

var addClientDescription$p = Utils.Translations.t("js.success_path.step_description.add_client");

var aboutClientPortalInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.about_client_portal");

var aboutClientPortal$p = Utils.Translations.tr("js.success_path.about_client_portal");

function HeaderSuccessPath$AddClient(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addClientDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addClientInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: aboutClientPortalInstructionUrl$p(ctx),
                  text: aboutClientPortal$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Client",
                    VAL: "Index"
                  },
                  children: addClient$p(ctx)
                }));
}

var uploadClientsInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.upload_clients");

var uploadClientsProgress$p = Utils.Translations.txr("js.success_path.progress.upload_clients");

var uploadClients$p = Utils.Translations.tr("js.success_path.upload_clients");

var uploadClientsDescription$p = Utils.Translations.t("js.success_path.step_description.upload_clients");

function HeaderSuccessPath$UploadClients(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "CountProgress" :
          var match = progress._0;
          var current = match.current;
          var target = match.target;
          tmp = current > 0 && target > 1 ? React.createElement("div", undefined, uploadClientsProgress$p({
                      count: target - current | 0
                    }, ctx)) : null;
          break;
      case "PercentProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: uploadClientsDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: uploadClientsInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Client",
                    VAL: "Index"
                  },
                  children: uploadClients$p(ctx)
                }));
}

var addTerritoryInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_territory");

var addTerritory$p = Utils.Translations.tr("js.success_path.add_territory");

var addTerritoryProgress$p = Utils.Translations.txr("js.success_path.progress.add_territory");

var addTerritoryDescription$p = Utils.Translations.t("js.success_path.step_description.add_territory");

function HeaderSuccessPath$AddTerritory(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "CountProgress" :
          var match = progress._0;
          var current = match.current;
          var target = match.target;
          tmp = current > 0 && target > 1 ? React.createElement("div", undefined, addTerritoryProgress$p({
                      count: target - current | 0
                    }, ctx)) : null;
          break;
      case "PercentProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addTerritoryDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addTerritoryInstructionUrl$p(ctx)
                }), React.createElement("div", {
                  className: styles.actionLink
                }, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "Territories",
                        VAL: "Index"
                      },
                      target: "blank",
                      children: null
                    }, addTerritory$p(ctx), " ", React.createElement("i", {
                          className: Icon.style(undefined, undefined, undefined, "external-link-alt")
                        }))));
}

function name(service) {
  if (service === "albato") {
    return "albato";
  } else {
    return "Zapier";
  }
}

var connectCRM$p = Utils.Translations.tr("js.success_path.connect_crm");

var connectBitrix24InstructionUrl$p = Utils.Translations.t("js.success_path.instructions.connect_bitrix24");

var connectKommoInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.connect_kommo");

var instructionsBitrix24$p = Utils.Translations.tr("js.success_path.instructions_bitrix24");

var instructionsKommo$p = Utils.Translations.tr("js.success_path.instructions_kommo");

function connectCRMDescription$p(serviceName, ctx) {
  var service = name(serviceName);
  return Utils.Translations.tx("js.success_path.step_description.connect_crm")({
              service: service
            }, ctx);
}

function HeaderSuccessPath$ConnectCRM(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var zapierLike = Context_Types.Region.isR0(ctx.region) ? "albato" : "zapier";
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: connectCRMDescription$p(zapierLike, ctx)
                }), zapierLike === "albato" ? React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$InstructionLink, {
                        ctx: ctx,
                        url: connectBitrix24InstructionUrl$p(ctx),
                        text: instructionsBitrix24$p(ctx)
                      }), React.createElement(HeaderSuccessPath$InstructionLink, {
                        ctx: ctx,
                        url: connectKommoInstructionUrl$p(ctx),
                        text: instructionsKommo$p(ctx)
                      }), React.createElement(HeaderSuccessPath$InstructionLink, {
                        ctx: ctx,
                        url: "https://albato.ru/app-planado",
                        text: "albato.ru"
                      })) : React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$InstructionLink, {
                        ctx: ctx,
                        url: connectKommoInstructionUrl$p(ctx),
                        text: instructionsKommo$p(ctx)
                      }), React.createElement(HeaderSuccessPath$InstructionLink, {
                        ctx: ctx,
                        url: connectBitrix24InstructionUrl$p(ctx),
                        text: instructionsBitrix24$p(ctx)
                      }), React.createElement(HeaderSuccessPath$InstructionLink, {
                        ctx: ctx,
                        url: "https://zapier.com/apps/planado/integrations",
                        text: "zapier.com"
                      })), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Integrations",
                    VAL: "Index"
                  },
                  children: connectCRM$p(ctx)
                }));
}

var setTerritoriesForWorkersInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.set_territories_for_workers");

var setTerritoriesForWorkersProgress$p = Utils.Translations.txr("js.success_path.progress.set_territories_for_workers");

var setTerritoriesForWorkers$p = Utils.Translations.tr("js.success_path.set_territories_for_workers");

var setTerritoriesForWorkersDescription$p = Utils.Translations.t("js.success_path.step_description.set_territories_for_workers");

function HeaderSuccessPath$SetTerritoriesForWorkers(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "CountProgress" :
          var match = progress._0;
          var current = match.current;
          var target = match.target;
          tmp = current > 0 && target > 1 ? React.createElement("div", undefined, setTerritoriesForWorkersProgress$p({
                      count: target - current | 0
                    }, ctx)) : null;
          break;
      case "PercentProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: setTerritoriesForWorkersDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: setTerritoriesForWorkersInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "User",
                    VAL: "Index"
                  },
                  children: setTerritoriesForWorkers$p(ctx)
                }));
}

var addSkillInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_skill");

var addSkillProgress$p = Utils.Translations.txr("js.success_path.progress.add_skill");

var addSkills$p = Utils.Translations.tr("js.success_path.add_skills");

var addSkillDescription$p = Utils.Translations.t("js.success_path.step_description.add_skill");

function HeaderSuccessPath$AddSkill(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "CountProgress" :
          var match = progress._0;
          var current = match.current;
          var target = match.target;
          tmp = current > 0 && target > 1 ? React.createElement("div", undefined, addSkillProgress$p({
                      count: target - current | 0
                    }, ctx)) : null;
          break;
      case "PercentProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addSkillDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addSkillInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: "Skills",
                  children: addSkills$p(ctx)
                }));
}

var setSkillsInTemplatesInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.set_skills_in_templates");

var setSkillsInTemplates$p = Utils.Translations.tr("js.success_path.set_skills_in_templates");

var setSkillsInTemplatesProgress$p = Utils.Translations.txr("js.success_path.progress.set_skills_in_templates");

var setSkillsInTemplatesDescription$p = Utils.Translations.t("js.success_path.step_description.set_skills_in_templates");

function HeaderSuccessPath$SetSkillsInTemplates(Props) {
  var wire = Props.wire;
  var progress = Props.progress;
  var ctx = wire.ctx;
  var tmp;
  if (progress !== undefined) {
    switch (progress.TAG) {
      case "CountProgress" :
          var match = progress._0;
          var current = match.current;
          var target = match.target;
          tmp = current > 0 && target > 1 ? React.createElement("div", undefined, setSkillsInTemplatesProgress$p({
                      count: target - current | 0
                    }, ctx)) : null;
          break;
      case "PercentProgress" :
      case "BoolProgress" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: setSkillsInTemplatesDescription$p(ctx)
                }), tmp, React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: setSkillsInTemplatesInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Template",
                    VAL: "Index"
                  },
                  children: setSkillsInTemplates$p(ctx)
                }));
}

var addRecurringJobInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_recurring_job");

var addRecurringJob$p = Utils.Translations.tr("js.success_path.add_recurring_job");

var addRecurringJobDescription$p = Utils.Translations.t("js.success_path.step_description.add_recurring_job");

function HeaderSuccessPath$AddRecurringJob(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addRecurringJobDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addRecurringJobInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "RecurringJob",
                    VAL: "Index"
                  },
                  children: addRecurringJob$p(ctx)
                }));
}

var configureCustomJobViewInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.configure_custom_job_view");

var configureCustomJobView$p = Utils.Translations.tr("js.success_path.configure_custom_job_view");

var configureCustomJobViewDescription$p = Utils.Translations.t("js.success_path.step_description.configure_custom_job_view");

function HeaderSuccessPath$ConfigureCustomJobView(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: configureCustomJobViewDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: configureCustomJobViewInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Job",
                    VAL: "Index"
                  },
                  children: configureCustomJobView$p(ctx)
                }));
}

var setUpSMSTemplateInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.set_up_sms_template");

var setUpSMSTemplate$p = Utils.Translations.tr("js.success_path.set_up_sms_template");

var setUpSMSTemplateDescription$p = Utils.Translations.t("js.success_path.step_description.set_up_sms_template");

function HeaderSuccessPath$SetUpSMSTemplate(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: setUpSMSTemplateDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: setUpSMSTemplateInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: "GeneralSettings",
                  children: setUpSMSTemplate$p(ctx)
                }));
}

var addTrackingPageToSMSTemplateInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_tracking_page_to_sms_template");

var addTrackingPageToSMSTemplate$p = Utils.Translations.tr("js.success_path.add_tracking_page_to_sms_template");

var addTrackingPageToSMSTemplateDescription$p = Utils.Translations.t("js.success_path.step_description.add_tracking_page_to_sms_template");

function HeaderSuccessPath$AddTrackingPageToSMSTemplate(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addTrackingPageToSMSTemplateDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addTrackingPageToSMSTemplateInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "ClientForm",
                    VAL: "Index"
                  },
                  children: addTrackingPageToSMSTemplate$p(ctx)
                }));
}

var finishJobInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.finish_job");

var finishJobDescription$p = Utils.Translations.t("js.success_path.step_description.finish_job");

function HeaderSuccessPath$FinishJob(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: finishJobDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: finishJobInstructionUrl$p(ctx)
                }));
}

var addGeojsonToTerritoryInstructionUrl$p = Utils.Translations.t("js.success_path.instructions.add_geojson_to_territory");

var addGeojsonToTerritory$p = Utils.Translations.tr("js.success_path.add_geojson_to_territory");

var addGeojsonToTerritoryDescription$p = Utils.Translations.t("js.success_path.step_description.add_geojson_to_territory");

function HeaderSuccessPath$AddGeojsonToTerritory(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(HeaderSuccessPath$Description, {
                  text: addGeojsonToTerritoryDescription$p(ctx)
                }), React.createElement(HeaderSuccessPath$InstructionLink, {
                  ctx: ctx,
                  url: addGeojsonToTerritoryInstructionUrl$p(ctx)
                }), React.createElement(HeaderSuccessPath$ActionLink, {
                  wire: wire,
                  route: {
                    NAME: "Territories",
                    VAL: "Index"
                  },
                  children: addGeojsonToTerritory$p(ctx)
                }));
}

function HeaderSuccessPath$Metric(Props) {
  var wire = Props.wire;
  var metric = Props.metric;
  if (Context_Types.SuccessPath.Metric.green(metric)) {
    return null;
  }
  var match = metric.code;
  var label;
  switch (match) {
    case "FinishedJobs" :
        label = finishedJobs$p;
        break;
    case "FieldUsersWithChanges" :
        label = fieldUsersWithChanges$p;
        break;
    case "JobsWithEnRouteTime" :
        label = jobsWithEnRouteTime$p;
        break;
    case "JobsWithWorkTime" :
        label = jobsWithWorkTime$p;
        break;
    case "JobsWithCustomTemplate" :
        label = jobsWithCustomTemplate$p;
        break;
    case "JobsWithCustomResolution" :
        label = jobsWithResolution$p;
        break;
    case "UsersWithGeoLocation" :
        label = usersWithGeoLocation$p;
        break;
    case "JobsWithClient" :
        label = jobsWithClient$p;
        break;
    case "JobsWithSentSms" :
        label = jobsWithSentSms$p;
        break;
    case "SmsWithTracking" :
        label = smsWithTracking$p;
        break;
    case "ScheduledJobs" :
        label = scheduledJobs$p;
        break;
    case "JobsWithTeam" :
        label = jobsWithTeam$p;
        break;
    case "JobsWithSkills" :
        label = jobsWithSkills$p;
        break;
    case "WorkersWithTerritories" :
        label = workersWithTerritories$p;
        break;
    
  }
  return React.createElement("div", {
              className: styles.successPathMetric
            }, React.createElement("div", {
                  className: styles.progressWrapper
                }, React.createElement(ProgressBar.make, {
                      percent: metric.percentage.percent
                    }), React.createElement("div", {
                      className: styles.progressLabel
                    }, label(metric.percentage.min, metric.lookbackDays, wire.ctx))));
}

function HeaderSuccessPath$StepWithMetric(Props) {
  var wire = Props.wire;
  var idx = Props.idx;
  var step = Props.step;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowDescription = match[1];
  var showDescription = match[0];
  var stepMetrics = Context_Types.SuccessPath.Step.metrics(step);
  var metrics = stepMetrics !== undefined ? stepMetrics.map(function (metric, idx) {
          return React.createElement(HeaderSuccessPath$Metric, {
                      wire: wire,
                      metric: metric,
                      key: idx.toString()
                    });
        }) : null;
  var stepStatus = stepMetrics !== undefined ? (
      stepMetrics.some(Context_Types.SuccessPath.Metric.red) ? "warning" : "checked"
    ) : "pending";
  var match$1 = step.code;
  var stepDescription;
  switch (match$1) {
    case "AddClient" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddClient, {
                  wire: wire
                }));
        break;
    case "AddCustomResolution" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddCustomResolution, {
                  wire: wire
                }));
        break;
    case "AddFieldWorkers" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddFieldWorkers, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "AddGeojsonToTerritory" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddGeojsonToTerritory, {
                  wire: wire
                }));
        break;
    case "AddOrEditTemplate" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddOrEditTemplate, {
                  wire: wire
                }));
        break;
    case "AddRecurringJob" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddRecurringJob, {
                  wire: wire
                }));
        break;
    case "AddSkill" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddSkill, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "AddTeam" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddTeam, {
                  wire: wire
                }));
        break;
    case "AddTerritory" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddTerritory, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "AddTrackingPageToSMSTemplate" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$AddTrackingPageToSMSTemplate, {
                  wire: wire
                }));
        break;
    case "ChooseTeamAsAssignee" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$ChooseTeamAsAssignee, {
                  wire: wire
                }));
        break;
    case "ConfigureCustomJobView" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$ConfigureCustomJobView, {
                  wire: wire
                }));
        break;
    case "ConnectCRM" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$ConnectCRM, {
                  wire: wire
                }));
        break;
    case "FinishJob" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$FinishJob, {
                  wire: wire
                }));
        break;
    case "SetSkillsInTemplates" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$SetSkillsInTemplates, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "SetTerritoriesForWorkers" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$SetTerritoriesForWorkers, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "SetUpShiftsForWorkers" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$SetUpShiftsForWorkers, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "SetUpShiftsForTeams" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$SetUpShiftsForTeams, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    case "SetUpSMSTemplate" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$SetUpSMSTemplate, {
                  wire: wire
                }));
        break;
    case "UploadClients" :
        stepDescription = Caml_option.some(React.createElement(HeaderSuccessPath$UploadClients, {
                  wire: wire,
                  progress: Context_Types.SuccessPath.Step.progress(step)
                }));
        break;
    
  }
  var stepIcon = stepStatus === "checked" ? React.createElement("span", {
          className: styles.successPathIcon + " " + styles.success + " " + styles.shown
        }, React.createElement("i", {
              className: Icon.style("lg", undefined, undefined, "check")
            })) : (
      stepStatus === "pending" ? React.createElement("span", {
              className: styles.successPathIcon
            }, React.createElement("i", {
                  className: Icon.style("lg", undefined, undefined, "check")
                })) : React.createElement("span", {
              className: styles.successPathIcon + " " + styles.warning + " " + styles.shown
            }, React.createElement("i", {
                  className: Icon.style("lg", undefined, undefined, "exclamation-triangle")
                }))
    );
  var tmp;
  if (stepDescription !== undefined) {
    var className = showDescription ? styles.successPathDescription + " " + styles.slide : styles.successPathDescription;
    tmp = React.createElement("span", {
          className: className
        }, Caml_option.valFromOption(stepDescription));
  } else {
    tmp = null;
  }
  return React.createElement("li", {
              key: idx.toString(),
              onClick: (function (param) {
                  setShowDescription(function (param) {
                        return !showDescription;
                      });
                })
            }, stepIcon, React.createElement("span", {
                  className: styles.successPathStep
                }, stepNumber$p(idx + 1 | 0, ctx), " ", React.createElement(HeaderSuccessPath$StepName, {
                      ctx: ctx,
                      step: step
                    }), " ", React.createElement("i", {
                      className: Icon.style("lg", undefined, "regular", showDescription ? "caret-up" : "caret-down")
                    })), metrics, tmp);
}

function HeaderSuccessPath(Props) {
  var wire = Props.wire;
  var successPath = Props.successPath;
  return React.createElement("ul", {
              className: styles.successPath + " " + styles.drop,
              onClick: (function (e) {
                  e.stopPropagation();
                })
            }, React.createElement("li", {
                  className: styles.successPathHeader
                }, React.createElement("span", undefined, header$p(wire.ctx))), successPath.steps.map(function (step, idx) {
                  return React.createElement(HeaderSuccessPath$StepWithMetric, {
                              wire: wire,
                              idx: idx,
                              step: step,
                              key: idx.toString()
                            });
                }));
}

var thresholds = [
  [
    85.0,
    "gauge-simple-max"
  ],
  [
    70.0,
    "gauge-simple-high"
  ],
  [
    45.0,
    "gauge-simple-low"
  ],
  [
    20.0,
    "gauge-simple-min"
  ]
];

function HeaderSuccessPath$Icon(Props) {
  var successPath = Props.successPath;
  var completed = successPath.steps.filter(function (step) {
        return Context_Types.SuccessPath.Step.complete(step);
      }).length;
  var total = successPath.steps.length;
  var percent = completed / total * 100.0;
  var iconClass = RCore.$$Option.getOr(RCore.$$Option.map(thresholds.find(function (param) {
                return percent >= param[0];
              }), (function (param) {
              return param[1];
            })), "gauge-simple-min");
  return React.createElement("i", {
              className: Icon.style("lg", undefined, undefined, iconClass)
            });
}

var SuccessPath;

var make = HeaderSuccessPath;

var Icon$1 = {
  make: HeaderSuccessPath$Icon
};

export {
  SuccessPath ,
  make ,
  Icon$1 as Icon,
}
/* header' Not a pure module */
